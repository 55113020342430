var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "div",
        {
          class: `flex items-center p-1 ${
            this.item.type !== "section"
              ? "justify-between"
              : "justify-start gap-2"
          }`,
        },
        [
          _vm.showFeedback && !_vm.showScoreInput
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _vm.showFeedback
                    ? _c("vs-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.bottom-start",
                            value: {
                              content: _vm.$t("adicionar-feedback"),
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n          content: $t('adicionar-feedback'),\n          delay: { show: 1000, hide: 100 },\n        }",
                            modifiers: { "bottom-start": true },
                          },
                        ],
                        staticClass: "float-left footer-button",
                        attrs: { type: "filled", icon: "add_comment" },
                        on: { click: _vm.toggleFeedbackModal },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.showScoreButton
            ? _c(
                "div",
                { class: _vm.showScoreButtonClass },
                [
                  _vm.showScoreButton
                    ? _c("vs-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.bottom-start",
                            value: {
                              content: _vm.$t("definir-pontuacao"),
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n          content: $t('definir-pontuacao'),\n          delay: { show: 1000, hide: 100 },\n        }",
                            modifiers: { "bottom-start": true },
                          },
                        ],
                        class: _vm.buttonScoreConfigClass,
                        attrs: { type: "filled", icon: "plus_one" },
                        on: { click: _vm.toggleScoreInput },
                      })
                    : _vm._e(),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showScoreInput,
                        expression: "showScoreInput",
                      },
                    ],
                    ref: "score",
                    staticClass: "float-left w-50 ml-1",
                    attrs: {
                      type: "number",
                      placeholder: _vm.isSmallScreen ? "" : _vm.$t("pontuacao"),
                      id: "score",
                    },
                    on: {
                      change: _vm.scoreChange,
                      keyup: _vm.validateKeyPresses,
                    },
                    model: {
                      value: _vm.score,
                      callback: function ($$v) {
                        _vm.score = $$v
                      },
                      expression: "score",
                    },
                  }),
                  _vm.showScoreInput
                    ? _c("vs-button", {
                        staticClass:
                          "float-left footer-button danger-color ml-1",
                        attrs: { type: "filled", icon: "clear" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelScoreInput.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showScoreInput
                    ? _c("vs-button", {
                        staticClass: "float-left footer-button primary-color",
                        attrs: { type: "filled", icon: "done" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggleScoreInput.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.showInputSizeButton && !_vm.showScoreInput
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _vm.showInputSizeButton
                    ? _c(
                        "v-popover",
                        {
                          staticClass: "float-left",
                          attrs: {
                            trigger: "manual",
                            open: _vm.showInputSize,
                            offset: "16",
                            "auto-hide": true,
                            "handle-resize": true,
                          },
                          on: {
                            "auto-hide": function ($event) {
                              _vm.showInputSize = false
                            },
                          },
                        },
                        [
                          _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.bottom-start",
                                value: {
                                  content: _vm.$t("tamanho-resposta"),
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{\n            content: $t('tamanho-resposta'),\n            delay: { show: 1000, hide: 100 },\n          }",
                                modifiers: { "bottom-start": true },
                              },
                            ],
                            staticClass:
                              "float-left footer-button tooltip-target b1 mt-1",
                            attrs: { type: "filled", icon: "format_size" },
                            on: { click: _vm.toggleInputSize },
                          }),
                          _c("template", { slot: "popover" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "vs-row",
                                  {
                                    class: _vm.showShortInputCheck
                                      ? "cursor-pointer success-color"
                                      : "cursor-pointer",
                                  },
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "left",
                                          "vs-align": "center",
                                          "vs-w": "3",
                                        },
                                      },
                                      [
                                        _vm.showShortInputCheck
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectInputCheck(
                                                      "short"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("check")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "left",
                                          "vs-align": "center",
                                          "vs-w": "9",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex bg-gray-200 float-left",
                                            staticStyle: {
                                              "min-width": "120px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectInputCheck(
                                                  "short"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("resposta-curta")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-row",
                                  {
                                    class: !_vm.showShortInputCheck
                                      ? "cursor-pointer success-color mt-2"
                                      : "cursor-pointer mt-2",
                                  },
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "left",
                                          "vs-align": "center",
                                          "vs-w": "3",
                                        },
                                      },
                                      [
                                        !_vm.showShortInputCheck
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectInputCheck(
                                                      "long"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("check")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "left",
                                          "vs-align": "center",
                                          "vs-w": "9",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex bg-gray-200 float-left",
                                            staticStyle: {
                                              "min-width": "120px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectInputCheck(
                                                  "long"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("resposta-longa")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.showScoreInput
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _vm.showDeleteBtn
                    ? _c("vs-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.bottom-start",
                            value: {
                              content: _vm.$t("excluir-questao"),
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n          content: $t('excluir-questao'),\n          delay: { show: 1000, hide: 100 },\n        }",
                            modifiers: { "bottom-start": true },
                          },
                        ],
                        staticClass: "float-left footer-button",
                        attrs: { type: "filled", icon: "delete_outline" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.deleteItem.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showInvalidateBtn
                    ? _c("vs-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.bottom-start",
                            value: {
                              content: _vm.$t("anular-questao-0"),
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n          content: $t('anular-questao-0'),\n          delay: { show: 1000, hide: 100 },\n        }",
                            modifiers: { "bottom-start": true },
                          },
                        ],
                        staticClass: "float-left footer-button",
                        attrs: { type: "filled", icon: "report" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.invalidation.showPrompt = true
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.showScoreInput
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _c("vs-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom-start",
                        value: {
                          content: _vm.$t("duplicar-questao"),
                          delay: { show: 1000, hide: 100 },
                        },
                        expression:
                          "{\n          content: $t('duplicar-questao'),\n          delay: { show: 1000, hide: 100 },\n        }",
                        modifiers: { "bottom-start": true },
                      },
                    ],
                    staticClass: "float-left footer-button",
                    attrs: { type: "filled", icon: "content_copy" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.duplicateItem.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.showScoreInput && _vm.showStoreButton && !_vm.showMoreButton
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _vm.showStoreButton
                    ? _c("vs-button", {
                        staticClass: "footer-button",
                        attrs: { type: "filled", icon: "archive" },
                        on: { click: _vm.storeBaseQuestion },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.item.type !== "section",
                expression: "this.item.type !== 'section'",
              },
            ],
            staticClass: "h-6 w-1 toolbar-more-border",
          }),
          _vm.showRequired && !_vm.showScoreInput
            ? _c("div", { class: _vm.toolbarRequiredClass }, [
                _c("div", { staticClass: "flex bg-gray-200" }, [
                  _c(
                    "div",
                    { staticClass: "grid grid-flow-col auto-cols-max" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-center" },
                        [
                          _c("vs-switch", {
                            model: {
                              value: _vm.required,
                              callback: function ($$v) {
                                _vm.required = $$v
                              },
                              expression: "required",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showButtonText
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center pl-2",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("obrigatoria"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.showMoreButton && !_vm.showScoreInput
            ? _c(
                "div",
                { class: _vm.toolbarMoreClass },
                [
                  _c(
                    "vs-dropdown",
                    {
                      attrs: {
                        "vs-custom-content": "",
                        "vs-trigger-click": "",
                      },
                    },
                    [
                      _c("vs-button", {
                        staticClass: "footer-button cursor-pointer",
                        attrs: { type: "filled", icon: "more_vert" },
                      }),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _vm.showStoreButton
                            ? _c(
                                "vs-dropdown-group",
                                { attrs: { "vs-label": _vm.$t("acao") } },
                                [
                                  _c(
                                    "vs-dropdown-item",
                                    { on: { click: _vm.storeBaseQuestion } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center gap-2 flex-row cursor-pointer",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("archive")]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "bg-gray-200 cursor-pointer",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "salvar-no-banco-de-questao"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "vs-dropdown-group",
                            { attrs: { "vs-label": _vm.$t("configuracao") } },
                            [
                              this.item.type === "multiple_choice"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      style: _vm.sortOptions
                                        ? "background-color: #dae1e7"
                                        : "",
                                      on: { click: _vm.toggleSortOptions },
                                    },
                                    [
                                      _c("div", { staticClass: "flex" }, [
                                        _c("div", { staticClass: "w-1/12" }, [
                                          _vm.sortOptions
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("check")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("div", { staticClass: "w-11/12" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex bg-gray-200 float-left",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "embaralhar-opcoes-aleatoriamente"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "vs-dropdown-item",
                                { staticClass: "hover:text-primary" },
                                [
                                  _c(
                                    "icon-with-expandable-input",
                                    {
                                      staticClass: "time-duration-footer",
                                      attrs: {
                                        placeholder: "",
                                        inputType: "number",
                                        inputMask: ["###"],
                                        buttonClasses: "p-0 w-auto h-auto",
                                        icon: "schedule",
                                      },
                                      on: { input: _vm.questionDurationChange },
                                      model: {
                                        value: _vm.question_duration,
                                        callback: function ($$v) {
                                          _vm.question_duration = $$v
                                        },
                                        expression: "question_duration",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.timeDurationInfo) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              this.item.type === "simple_text"
                                ? _c(
                                    "vs-dropdown-item",
                                    { staticClass: "hover:text-primary" },
                                    [
                                      _c(
                                        "icon-with-expandable-input",
                                        {
                                          staticClass: "time-duration-footer",
                                          attrs: {
                                            placeholder: "",
                                            inputType: "number",
                                            inputMask: ["####"],
                                            buttonClasses: "p-0 w-auto h-auto",
                                            icon: "straighten",
                                          },
                                          on: {
                                            input: _vm.characterLimitChange,
                                          },
                                          model: {
                                            value: _vm.character_limit,
                                            callback: function ($$v) {
                                              _vm.character_limit = $$v
                                            },
                                            expression: "character_limit",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.characterLimitInfo) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              this.item.type === "essay"
                                ? _c(
                                    "vs-dropdown-item",
                                    {
                                      staticClass: "hover:text-primary",
                                      on: { click: _vm.showEssayLimits },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center gap-2 flex-row cursor-pointer",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("wysiwyg")]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "bg-gray-200 cursor-pointer",
                                            },
                                            [_vm._v("Configurar Limites")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          this.item.type === "multiple_choice"
                            ? _c(
                                "vs-dropdown-group",
                                { attrs: { "vs-label": "Enumerar Questão" } },
                                [
                                  this.item.type === "multiple_choice"
                                    ? _c(
                                        "vs-dropdown-item",
                                        {
                                          style: _vm.questioEnumerateNumber
                                            ? "background-color: #dae1e7"
                                            : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleEnumerateQuestionNumber(
                                                "number"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-1/12" },
                                              [
                                                _vm.questioEnumerateNumber
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      },
                                                      [_vm._v("check")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-11/12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex bg-gray-200 float-left",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "enumerar-questao-com-numeros"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  this.item.type === "multiple_choice"
                                    ? _c(
                                        "vs-dropdown-item",
                                        {
                                          style: _vm.questioEnumerateLetter
                                            ? "background-color: #dae1e7"
                                            : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleEnumerateQuestionNumber(
                                                "letter"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-1/12" },
                                              [
                                                _vm.questioEnumerateLetter
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      },
                                                      [_vm._v("check")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-11/12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex bg-gray-200 float-left",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "enumerar-questao-com-letras"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("adicionar-feedback"),
            active: _vm.showFeedbackModal,
            fullscreen: "true",
          },
          on: {
            "update:active": function ($event) {
              _vm.showFeedbackModal = $event
            },
          },
        },
        [
          _c(
            "vs-tabs",
            {
              attrs: { color: _vm.colorx },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("resposta-correta") } },
                [
                  _c("ComplexEditor", {
                    attrs: {
                      questionnaire_id: _vm.parent_id,
                      editor_data: _vm.correctFeedback.editorData,
                      placeholder: _vm.$t("digite-aqui-o-feedback"),
                    },
                    on: {
                      "update:editor_data": function ($event) {
                        return _vm.$set(
                          _vm.correctFeedback,
                          "editorData",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("resposta-incorreta") } },
                [
                  _c("ComplexEditor", {
                    attrs: {
                      questionnaire_id: _vm.parent_id,
                      editor_data: _vm.wrongFeedback.editorData,
                      placeholder: _vm.$t("digite-aqui-o-feedback"),
                    },
                    on: {
                      "update:editor_data": function ($event) {
                        return _vm.$set(_vm.wrongFeedback, "editorData", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-wrap float-right" },
            [
              _c(
                "vs-button",
                { staticClass: "mr-2", on: { click: _vm.updateFeedback } },
                [_vm._v(_vm._s(_vm.$t("adicionar")))]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.toggleFeedbackModal },
                },
                [_vm._v(_vm._s(_vm.$t("action.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "invalidation-popup",
          attrs: {
            title: _vm.$t("anular-questao"),
            color: "danger",
            active: _vm.invalidation.showPrompt,
          },
          on: {
            "update:active": function ($event) {
              return _vm.$set(_vm.invalidation, "showPrompt", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              key: _vm.invalidation.key,
              staticClass: "flex gap-4 flex-col pb-6",
            },
            [
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("tem-certeza-que-deseja-anular-esta-questao")
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                [
                  _c("label", { staticClass: "block font-bold text-sm" }, [
                    _vm._v(" " + _vm._s(_vm.$t("justificativa")) + " "),
                  ]),
                  _c("ComplexEditor", {
                    attrs: {
                      editor_data: _vm.invalidation_justification,
                      placeholder: _vm.$t(
                        "opcional-justifique-porque-a-questao"
                      ),
                      dontClose: true,
                    },
                    on: {
                      "update:editor_data": function ($event) {
                        _vm.invalidation_justification = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("o-que-devera-ser-feito-com-a-nota")) +
                    " "
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "vs-radio",
                    {
                      staticClass: "py-2 w-full",
                      attrs: {
                        "vs-name": "invalidation_type",
                        "vs-value": "replace",
                      },
                      model: {
                        value: _vm.invalidation_type,
                        callback: function ($$v) {
                          _vm.invalidation_type = $$v
                        },
                        expression: "invalidation_type",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("manter")) + " ")]
                  ),
                  _c(
                    "vs-radio",
                    {
                      staticClass: "py-2 w-full",
                      attrs: {
                        "vs-name": "invalidation_type",
                        "vs-value": "zero",
                      },
                      model: {
                        value: _vm.invalidation_type,
                        callback: function ($$v) {
                          _vm.invalidation_type = $$v
                        },
                        expression: "invalidation_type",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("zerar")) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full pb-2 flex gap-2 flex-row items-end justify-end",
            },
            [
              _vm.itemIsInvalidated()
                ? _c(
                    "vs-button",
                    {
                      attrs: { icon: "undo", type: "border", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleInvalidationPrompt("clear")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("limpar")) + " ")]
                  )
                : _vm._e(),
              _c(
                "vs-button",
                {
                  attrs: {
                    type: "filled",
                    color: _vm.itemIsInvalidated() ? "primary" : "danger",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleInvalidationPrompt("accept")
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.itemIsInvalidated() ? "Alterar" : "Anular") +
                      " "
                  ),
                ]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "border", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleInvalidationPrompt("cancel")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("voltar")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }